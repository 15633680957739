body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: "100%";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b9090b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b9090b;
}

html *{font-size:100%;}

@media only screen and (min-width: 1280px) {
  #Container {
    width: 95%;
  }

  #PageContent {
    margin: 0px auto;
    max-width: 100%;
  }

  #PageInfo {
    max-width: 100%;
  }

  .InfoContent {
    max-width: 100%;
  }

  .c640 {
    width: 20%;
  }

  .c640 .box {
    width: 100%;
  }

  #navigation {
    width: auto;
  }

  .box {
    float: none;
  }
}

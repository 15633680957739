body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: "100%";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b9090b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b9090b;
}

html *{font-size:100%;}

@media only screen and (min-width: 1280px) {
  #Container {
    width: 95%;
  }

  #PageContent {
    margin: 0px auto;
    max-width: 100%;
  }

  #PageInfo {
    max-width: 100%;
  }

  .InfoContent {
    max-width: 100%;
  }

  .c640 {
    width: 20%;
  }

  .c640 .box {
    width: 100%;
  }

  #navigation {
    width: auto;
  }

  .box {
    float: none;
  }
}

.sc-emoji-picker {
  overflow: auto;
  width: 100%;
  max-height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 15px;
}

.sc-emoji-picker--category {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.sc-emoji-picker--category-title {
  min-width: 100%;
  color: #b8c3ca;
  font-weight: 200;
  font-size: 13px;
  margin: 5px;
  letter-spacing: 1px;
}

.sc-emoji-picker--emoji {
  margin: 5px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  font-size: 28px;
  transition: transform 60ms ease-out,-webkit-transform 60ms ease-out;
  transition-delay: 60ms;
}

.sc-emoji-picker--emoji:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}
.sc-chat-window {
  width: 350px;
  height: calc(100% - 250px);
  max-height: 575px;
  position: fixed;
  margin-right: 10px;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  z-index: 9999999;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sc-chat-window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
}

.sc-message-list {
  height: 80%;
  overflow-y: auto;
  background-color: white;
  background-size: 100%;
  padding: 20px 0px;
}

.sc-message--me {
  text-align: right;
}
.sc-message--them {
  text-align: left;
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 9999999;
    border-radius: 0px;
  }
  .sc-chat-window {
    transition: 0.1s ease-in-out;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}
.sc-header {
  min-height: 50px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  position: relative;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
}

.sc-header--team-name {
  padding: 10px;
  -webkit-align-self: center;
          align-self: center;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}
.sc-message {
  margin: auto;
  width: 100%;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
}

.sc-message--content {
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.sc-message--content.sent {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.sc-message--content.sent .sc-message--avatar {
  display: none;
}

.sc-message--avatar {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  -webkit-align-self: center;
          align-self: center;
  margin-right: 15px;
}

.sc-message--text {
  padding: 8px 8px;
  border-radius: 5px;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: calc(100% - 90px);
}

.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #B9090B;
  max-width: calc(100% - 120px);
  word-wrap: break-word;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
  margin-right: 40px;
}

.sc-message--emoji {
  font-size: 40px;
}

.sc-message--file {
  background: white;
  border: solid 1px #CCCDD1;
  padding: 15px 20px;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
}

.sc-message--file p {
  margin: 0px 0px 0px 10px;
  color: rgba(86, 88, 103, 0.6);
}

.sc-message--file .sc-user-input--file-icon:hover path {
  fill: rgba(86, 88, 103, 0.3);
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}
.sc-user-input {
  min-height: 55px;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color .2s ease,box-shadow .2s ease;
}


.sc-user-input--text {
  width: 300px;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  max-height: 200px;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: rgba(86, 88, 103, 0.3);
  outline: none;
}

.sc-user-input--buttons {
  width: 100px;
  position: absolute;
  right: 10px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.sc-user-input--button:first-of-type {
  width: 40px;
}

.sc-user-input--button {
  width: 30px;
  height: 55px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.sc-user-input--button button {
  cursor: pointer;
}

.sc-user-input--buttons input[type="file"] {
  display: none;
}

.sc-user-input--picker-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sc-user-input.active {
  box-shadow: none;
  background-color: white;
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
}

.sc-user-input--file-icon,
.sc-user-input--send-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  -webkit-align-self: center;
          align-self: center;
  outline: none;
}

.sc-user-input--file-icon path,
.sc-user-input--send-icon path {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--file-icon:hover path,
.sc-user-input--send-icon:hover path {
  fill: rgba(86, 88, 103, 1);
}

.sc-user-input--emoji-icon-wrapper,
.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  background: none;
  border: none;
  padding: 2px;
  margin: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.sc-user-input--emoji-icon-wrapper:focus {
  outline: none;
}

.sc-user-input--emoji-icon {
  height: 18px;
  cursor: pointer;
  -webkit-align-self: center;
          align-self: center;
}

.sc-user-input--emoji-icon path, .sc-user-input--emoji-icon circle {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon circle,
.sc-user-input--emoji-icon.active path,
.sc-user-input--emoji-icon.active circle,
.sc-user-input--emoji-icon:hover path,
.sc-user-input--emoji-icon:hover circle {
  fill: rgba(86, 88, 103, 1);
}
.sc-popup-window {
  position: relative;
  width: 150px;
}

.sc-popup-window--cointainer {
  position: absolute;
  bottom: 20px;
  right: 100px;
  width: 330px;
  max-height: 260px;
  height: 260px;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  border-radius: 10px;
  outline: none;
  transition: 0.2s ease-in-out;
  z-index: -9999999;
  padding: 0px 5px 5px 5px;
  box-sizing: border-box;
}

.sc-popup-window--cointainer.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 14px;
}

.sc-popup-window--cointainer:after {
  content: "";
  width: 14px;
  height: 14px;
  background: white;
  position: absolute;
  z-index: -1999999999;
  bottom: -6px;
  right: 28px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 2px;
}

.sc-popup-window--search {
  width: 290px;
  box-sizing: border-box;
  margin: auto;
  display: block;
  border-width: 0px 0px 1px 0px;
  color: #565867;
  padding-left: 25px;
  height: 40px;
  font-size: 14px;
  background-image: url(https://js.intercomcdn.com/images/search@2x.32fca88e.png);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 0 12px;
  outline: none;
}

.sc-popup-window--search::-webkit-input-placeholder {
  color: #C1C7CD;
}

.sc-popup-window--search::-ms-input-placeholder {
  color: #C1C7CD;
}

.sc-popup-window--search::placeholder {
  color: #C1C7CD;
}
.page-not-found {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 40px;
    border: 1px solid #c8c8c8;
    text-align: center;
}

.page-not-found .title {
    font-size: 50px;
    letter-spacing: 10px;
    margin-bottom: 10px;
}

.page-not-found .desc {
    font-size: 20px;
    margin-bottom: 20px;
}

.go-back-btn {
    min-width: 160px;
}

.sc-header {
  min-height: 50px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--team-name {
  padding: 10px;
  align-self: center;
  flex: 1;
  user-select: none;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}